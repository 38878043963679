import {Injectable} from '@angular/core';
import {AppConstant} from '../global/common-file/constant';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AstromallService {
  // private allCategories = AppConstant.astroMallApi + AppConstant.astroMallCategories;
  private allCategories = AppConstant.astroMallApi + AppConstant.astroMallCategories2;
  // private productsByCategories = AppConstant.apiUrl + AppConstant.astroMallproducts;
  private productsByCategories2 = AppConstant.astroMallApi + AppConstant.astroMallproducts2;
  // private productsDetails = AppConstant.apiUrl + AppConstant.productsDetails;
  private productsDetails2 = AppConstant.astroMallApi + AppConstant.productsDetails2;
  // private productsImages = AppConstant.apiUrl + AppConstant.productsImages;
  private productsImages2 = AppConstant.astroMallApi + AppConstant.productsImages2;
  private searchProduct = AppConstant.astroMallApi + AppConstant.productSearch;
  // private intakeProduct = AppConstant.astroMallApi + AppConstant.intakeProduct;
  private productConsultants = AppConstant.astroMallApi + AppConstant.productConsultant;
  private productIntake = AppConstant.astroMallApi + AppConstant.productIntake;
  // private astroBanners = AppConstant.astroMallApi + AppConstant.astroBanners;
  private productLines = AppConstant.astroMallApi + AppConstant.productLines;
  private productQuestions = AppConstant.astroMallApi + AppConstant.productQuestions;
  private productAddOns = AppConstant.astroMallApi + AppConstant.productAddOns;
  private productRatingReviews = AppConstant.apiUrl + AppConstant.productRatingReviews;
  // private getSavedAddresses = AppConstant.apiUrl + AppConstant.getSavedAddresses;
  private getSavedAddresses = AppConstant.astroMallApi + AppConstant.getSavedAddresses;
  // private saveAddress = AppConstant.apiUrl + AppConstant.saveAddress;
  private saveAddress2 = AppConstant.astroMallApi + AppConstant.saveAddress2;
  // private updateSingleAddress = AppConstant.apiUrl + AppConstant.updateAddress;
  private updateSingleAddress2 = AppConstant.astroMallApi + AppConstant.updateAddress2;
  private deleteSavedAddress = AppConstant.apiUrl + AppConstant.deleteSavedAddress;
  private paymentGatewayList = AppConstant.apiUrl + AppConstant.paymentGatewayList;
  private startPayment = AppConstant.apiUrl + AppConstant.startPayment;
  private completePayment = AppConstant.apiUrl + AppConstant.completePayment;
  private automatedGroupChatMsg = AppConstant.astroMallApi + AppConstant.automatedGroupChatMsg;
  private initPaytm = AppConstant.apiUrl + AppConstant.initPaytm;
  private getMobile = AppConstant.apiUrl + AppConstant.getMobile;
  private buyNowAndSave = AppConstant.astroMallApi + AppConstant.buyNowAndSave;
  private selectConsultantID = AppConstant.astroMallApi + AppConstant.selectConsultantID;
  private selectAndSaveAddress = AppConstant.astroMallApi + AppConstant.selectAndSaveAddress;
  private selectedDetails = AppConstant.astroMallApi + AppConstant.selectedDetails;
  // private finalPrice = AppConstant.astroMallApi + AppConstant.finalPrice;
  private rattiPrice = AppConstant.astroMallApi + AppConstant.rattiPrice;
  private orderFromWallet = AppConstant.apiUrl + AppConstant.makePaymentFromWallet;

  constructor(private http: HttpWrapperService) {
  }

  public getAstromallCategories = (params) => {
    return this.http.get(this.allCategories, params, false);
  }
  public getAstromallProducts2 = (params) => {
    return this.http.get(this.productsByCategories2, params, false);
  }
  public getProductDetails2 = (params) => {
    return this.http.get(this.productsDetails2, params, false);
  }
  public getProductImages2 = (params) => {
    return this.http.get(this.productsImages2, params, false);
  }
  public searchProducts = (params) => {
    return this.http.get(this.searchProduct, params, false);
  }
  /* public getProductIntake = (params, header) =>  {
     return this.http.get(this.intakeProduct, params, header);
   }*/
  public getProductConsultants = (params) => {
    return this.http.get(this.productConsultants, params, false);
  }
  public submitIntakeProductForm = (params) => {
    return this.http.postHeaderParams(this.productIntake, params, true);
  }
  // public getAstroBanner = (params) => {
  //   return this.http.get(this.astroBanners, params, false);
  // }
  public getProductLines = (params) => {
    return this.http.get(this.productLines, params, false);
  }
  public getProductQuestions = (params) => {
    return this.http.get(this.productQuestions, params, false);
  }
  public getProductAddOns = (params) => {
    return this.http.get(this.productAddOns, params, false);
  }
  public getRatingReviews = (params, header) => {
    return this.http.get(this.productRatingReviews, params, header);
  }
  public getSavedAddress = (params) => {
    return this.http.get(this.getSavedAddresses, params, true);
  }
  public saveAndCreateAddress2 = (params) => {
    return this.http.postHeaderParams(this.saveAddress2, params, true);
  }
  public updateAddress2 = (params) => {
    return this.http.postHeaderParams(this.updateSingleAddress2, params, true);
  }
  public deleteAddress = (params) => {
    return this.http.deleteQueryParam(this.deleteSavedAddress, params, true);
  }
  public getAllGatewayList = (params) => {
    return this.http.get(this.paymentGatewayList, params, true);
  }
  public makePaymentComplete = (params) => {
    return this.http.postHeaderParams(this.completePayment, params, true);
  }
  public automatedChatMsg = (params) => {
    return this.http.postHeaderParams(this.automatedGroupChatMsg, params, true);
  }
  public initiatePaytm = (params) => {
    return this.http.postHeaderParams(this.initPaytm, params, true);
  }

  public getTransactionId = (params) => {
    return this.http.postHeaderParams(this.startPayment, params, true);
  }

  public getMobileNumber = (params) => {
    return this.http.get(this.getMobile, params, true);
  }

  public bookNow = (params) => {
    return this.http.postHeaderParams(this.buyNowAndSave, params, true);
  }

  public selectConsultant = (params) => {
    return this.http.postHeaderParams(this.selectConsultantID, params, true);
  }

  public selectAddress = (params) => {
    return this.http.postHeaderParams(this.selectAndSaveAddress, params, true);
  }

  public getSelectedDetails = (params) => {
    return this.http.get(this.selectedDetails, params, true);
  }

  public calculateRatti = (params) => {
    return this.http.get(this.rattiPrice, params, true);
  }

  public makePaymentFromWallet = (params) => {
    return this.http.postHeaderParams(this.orderFromWallet, params, true);
  }
}
