import {Injectable} from '@angular/core';
import {AppConstant} from '../global/common-file/constant';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class CallingService {
  private astrologerListForCallApi = AppConstant.apiUrlForList + AppConstant.astrologerListForCallApi;
  private walletBalenceApi = AppConstant.apiUrl + AppConstant.walletBalenceApi;
  private getRecordingApi = AppConstant.apiUrl + AppConstant.getRecordingApi;
  private notifyConsultantApi = AppConstant.apiUrl + AppConstant.notifyConsultantApi;
  private checkCallStatusApi = AppConstant.apiUrl + AppConstant.checkCallStatusApi;
  private checkNextOnlineTimeApi = AppConstant.apiUrl + AppConstant.checkNextOnlineTimeApi;
  private intakeDataApi = AppConstant.apiUrl + AppConstant.intakeDataApi;
  private postIntakeDataApi = AppConstant.apiUrl + AppConstant.postIntakeDataApi;
  private waitListApi = AppConstant.apiUrl + AppConstant.waitListApi;
  private initiateCallApi = AppConstant.apiUrl + AppConstant.initiateCallApi;
  private initiateCallApiSession = AppConstant.apiUrl + AppConstant.initiateCallApiSession;
  private getSuggestion = AppConstant.supportApiUrl + AppConstant.getCities;
  private getSecondPlace = AppConstant.locationApiDomain + AppConstant.getSecondPlace;
  private addNewCity = AppConstant.supportApiUrl + AppConstant.addNewCity;

  constructor(private http: HttpWrapperService) {}

  getAstrologers = (params, isLogin = false) => {
    return this.http.get(this.astrologerListForCallApi, params, isLogin);
  }

  checkCallStatus = (params) => {
    return this.http.postParams(this.checkCallStatusApi, params, true);
  }

  checkNextOnlineTime = (params) => {
    return this.http.get(this.checkNextOnlineTimeApi, params, true);
  }

  getBalence = (params) => {
    return this.http.get(this.walletBalenceApi, params, true);
  }

  getRecording = (params) => {
    return this.http.get(this.getRecordingApi, params);
  }

  notify = (body) => {
    return this.http.postParams(this.notifyConsultantApi, body, true);
  }

  getIntakeData = (params) => {
    return this.http.get(this.intakeDataApi, params, true);
  }

  submitIntakeData = (body) => {
    return this.http.post(this.postIntakeDataApi, body, true);
  }

  submitInitiateCall = (body) => {
    return this.http.post(this.initiateCallApi, body, true);
  }

  getWaitList = (params) => {
    return this.http.get(this.waitListApi, params, true);
  }

  public getCitySuggestion = (params) => {
    return this.http.get(this.getSuggestion, params, false);
  }

  public getSecondPlaces = (params) => {
    return this.http.get(this.getSecondPlace, params, false);
  }

  public addAddress = (params) => {
    return this.http.postHeaderParams(this.addNewCity, params, false);
  }

  submitInitiateCallSession = (body) => {
    return this.http.post(this.initiateCallApiSession, body, true);
  }
}
