import {Injectable} from '@angular/core';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';
import {AppConstant} from '../global/common-file/constant';

@Injectable({
  providedIn: 'root'
})
export class KundliService {

  private getKundli = AppConstant.apiUrl + AppConstant.getKundli;
  private searchSavedHoroscope = AppConstant.apiUrl + AppConstant.searchHoroscope;
  private getSuggestion = AppConstant.supportApiUrl + AppConstant.getCities;
  // private getSuggestion = AppConstant.kundliUrl + AppConstant.getCities;
  private getSecondPlace = AppConstant.locationApiDomain + AppConstant.getSecondPlace;
  private addNewCity = AppConstant.supportApiUrl + AppConstant.addNewCity;
  // private addNewCity = AppConstant.kundliUrl + AppConstant.addNewCity;
  private saveHoroscopeGetID = AppConstant.apiUrl + AppConstant.saveHoroscope;
  private deleteHoroscope = AppConstant.apiUrl + AppConstant.deleteHoroscope;
  private kundliMobileGenerated = AppConstant.apiUrl + AppConstant.kundliMobileGenerated;
  private kundliMobileGeneratedChat = AppConstant.chatApi + AppConstant.kundliMobileGeneratedChat;
  private encryptId = AppConstant.kundliUrl + AppConstant.encryptId;
  private dashaComplete = AppConstant.kundliUrl + AppConstant.dashaComplete;
  private ashtakvarga = AppConstant.kundliUrl + AppConstant.ashtakvarga;

// combined apis
  private combinedGeneral = AppConstant.kundliUrl + AppConstant.combinedGeneral;
  private combinedDivision = AppConstant.kundliUrl + AppConstant.combinedDivision;
  private combinedReport = AppConstant.kundliUrl + AppConstant.combinedReport;

  private yogaDetails = AppConstant.kundliUrl + AppConstant.yogaDetails;
  private yoginiDetails = AppConstant.kundliUrl + AppConstant.yoginiDetails;

  private kundliFromLiveEvent = AppConstant.kundliUrl + AppConstant.kundliFromList;

  constructor(private http: HttpWrapperService) {
  }

  public getKundliList = (params) => {
    return this.http.get(this.getKundli, params, true);
  }

  public getCitySuggestion = (params) => {
    return this.http.get(this.getSuggestion, params, false);
  }

  public getSecondPlaces = (params) => {
    return this.http.get(this.getSecondPlace, params, false);
  }

  public addAddress = (params) => {
    return this.http.postHeaderParams(this.addNewCity, params, false);
  }

  public saveHoroscopeAndGetID = (params, body) => {
    return this.http.postHeaderBodyAndParams(this.saveHoroscopeGetID, params, body, true);
  }

  public searchHoroscope = (params) => {
    return this.http.get(this.searchSavedHoroscope, params, true);
  }

  public deleteSavedHoroscope = (params) => {
    return this.http.get(this.deleteHoroscope, params, true);
  }

  public getKungliMobile = (params) => {
    if (params.serviceId == 4) {
      return this.http.get(this.kundliMobileGeneratedChat, params);
    } else {
      return this.http.get(this.kundliMobileGenerated, params);
    }
  }
/*

  public getBirthDetails(params) {
    return this.http.post(this.birthDetails, params, false);
  }

  public getAstroDetails(params) {
    return this.http.post(this.astroDetails, params, false);
  }

  public getPredictionDetails(params) {
    return this.http.post(this.predictionDetails, params, false);
  }

  public getYogaDetails(params) {
    return this.http.post(this.yogaDetails, params, false);
  }

  public getKalsarpa(params) {
    return this.http.post(this.kalsarpaDetails, params, false);
  }

  public getAscendant(params) {
    return this.http.post(this.ascendantData, params, false);
  }

  public getPlanetsExtended(params) {
    return this.http.post(this.planetDetails, params, false);
  }

  public getDashaComplete(params) {
    return this.http.post(this.dashaComplete, params, false);
  }

  public getManglikReport(params) {
    return this.http.post(this.manglik, params, false);
  }

*/

  public getYogaDetails = (params) => {
    return this.http.post(this.yogaDetails, params, false);
  }

  public getYoginiDetails = (params) => {
    return this.http.post(this.yoginiDetails, params, false);
  }

  public getDashaComplete(params) {
    return this.http.post(this.dashaComplete, params, false);
  }

  getAshtakvargaDetails = (params) => {
    return this.http.post(this.ashtakvarga, params, false);
  }

  // Chart Api..................

/*

  public generateLagnaDetails(params) {
    return this.http.postJson(this.chartLagna, params, false);
  }

  public generateNavashmaDetails(params) {
    return this.http.postJson(this.chartNavashma, params, false);
  }

  public generateChalitDetails(params) {
    return this.http.postJson(this.chartChalit, params, false);
  }

  public generateSunDetails(params) {
    return this.http.postJson(this.chartSun, params, false);
  }

  public generateMoonDetails(params) {
    return this.http.postJson(this.chartMoon, params, false);
  }

  public chartHoraDetails(params) {
    return this.http.postJson(this.chartHora, params, false);
  }

  public chartDrekkanaDetails(params) {
    return this.http.postJson(this.chartDrekkana, params, false);
  }

  public chartChaturthamsaDetails(params) {
    return this.http.postJson(this.chartChaturthamsa, params, false);
  }

  public chartSaptamsaDetails(params) {
    return this.http.postJson(this.chartSaptamsa, params, false);
  }

  public chartDasamsaDetails(params) {
    return this.http.postJson(this.chartDasamsa, params, false);
  }

  public chartDwadasamsaDetails(params) {
    return this.http.postJson(this.chartDwadasamsa, params, false);
  }

  public chartShodasamsaDetails(params) {
    return this.http.postJson(this.chartShodasamsa, params, false);
  }

  public chartVimsamsaDetails(params) {
    return this.http.postJson(this.chartVimsamsa, params, false);
  }

  public chartChaturvimsamsaDetails(params) {
    return this.http.postJson(this.chartChaturvimsamsa, params, false);
  }

  public chartSaptavimsamsaDetails(params) {
    return this.http.postJson(this.chartSaptavimsamsa, params, false);
  }

  public chartTrimsamsaDetails(params) {
    return this.http.postJson(this.chartTrimsamsa, params, false);
  }

  public chartKhavedamsaDetails(params) {
    return this.http.postJson(this.chartKhavedamsa, params, false);
  }

  public chartAkshavedamsaDetails(params) {
    return this.http.postJson(this.chartAkshavedamsa, params, false);
  }

  public chartShastiamsaDetails(params) {
    return this.http.postJson(this.chartShastiamsa, params, false);
  }

  public getVimShottariMajorDasha(params) {
    return this.http.postJson(this.vimShottariMajor, params, false);
  }

*/

  public getEncryptedID = (params) => {
    return this.http.get(this.encryptId, params);
  }
  // Combined apis
  public getCombinedGeneral = (params) => {
    return this.http.post(this.combinedGeneral, params);
  }

  public getCombinedDivision = (params) => {
    return this.http.post(this.combinedDivision, params);
  }

  public getCombinedReport = (params) => {
    return this.http.post(this.combinedReport, params);
  }

  public getKundliLiveEvent = (params): any => {
    return this.http.get(this.kundliFromLiveEvent, params, false);
  }
}
