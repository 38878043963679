export class AstrologerListModel {
  isForgen = false;
  firstname: string;
  profilePic: string;
  isNotify: boolean;
  verified: boolean;
  language: string;
  isNew: boolean;
  label: string;
  isPoSo: boolean;
  experience = '0';
  url: string;
  price: number;
  discountedPrice: number;
  visibleLocal: boolean;
  status: string;
  skill: string;
  skills: any = [];
  noOfRating: number;
  avgRating: number;
  id: number;
  waitListWaitTime = 0;
  offlineWaitListWaitTime = 0;
  offlineNextOnlineTime: number;
  offlineNextOnlineTimeLong: number;
  isWaitListJoined = false;
  hasOffer = false;
  waitListSize: number;
  nextOnlineTimeChat: string;
  nextOnlineTimeCall: string;
  cashbackOfferValue: number;
  title: string;
  hasPromotionalOffer: boolean;
  isFirstSession: boolean;
  promotionalOfferMin: number;
  tag: string;
  isIntroVideoActive = false;
  introVideo: string;
  isBoostOn = false;
  offerDisplayName: string;
  isFavourite: boolean;
  waitlistSizeAll: number;
  isShowOffer: boolean;
  netPriceInCurrency: number;
  currencySymbol: string;
  priceNewInCurrency: number;
}
