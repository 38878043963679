import {Injectable} from '@angular/core';
import {AppConstant} from '../global/common-file/constant';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ChattingService {

  private astrologerListForCallApi = AppConstant.apiUrlForList + AppConstant.astrologerListForCallApi;
  private walletBalenceApi = AppConstant.apiUrl + AppConstant.walletBalenceApi;
  private notifyConsultantApi = AppConstant.apiUrl + AppConstant.notifyConsultantApi;
  private checkChatStatusApi = AppConstant.apiUrl + AppConstant.checkCallStatusApi;
  private checkNextOnlineTimeApi = AppConstant.apiUrl + AppConstant.checkNextOnlineTimeApi;
  private intakeDataApi = AppConstant.apiUrl + AppConstant.intakeDataApi;
  private postIntakeDataApi = AppConstant.chatApi + AppConstant.postIntakeDataChatApi;
  private initiateChatApi = AppConstant.apiUrl + AppConstant.initiateCallApi;
  private createChatOrderApi = AppConstant.apiUrl + AppConstant.createChatOrderApi;
  private waitListApi = AppConstant.apiUrl + AppConstant.waitListApi;
  private chatOrderApi = AppConstant.apiUrl + AppConstant.chatOrderApi;
  private chatListApi = AppConstant.chatApi + AppConstant.chatListApi;
  private lastMessageApi = AppConstant.chatApi + AppConstant.lastMessageApi;
  private sendMessageApi = AppConstant.chatApi + AppConstant.sendChatMessage;
  private chatEndedApi = AppConstant.chatApi + AppConstant.chatEndedApi;
  private rattingApi = AppConstant.apiUrl + AppConstant.rattingApi;
  private deleteMessageApi = AppConstant.chatApi + AppConstant.deleteMessageApi;
  private sharedChatApi = AppConstant.apiUrl + AppConstant.sharedChatApi;
  private getSuggestion = AppConstant.supportApiUrl + AppConstant.getCities;
  private getSecondPlace = AppConstant.locationApiDomain + AppConstant.getSecondPlace;
  private addNewCity = AppConstant.supportApiUrl + AppConstant.addNewCity;
  private antiFraudFlagsAPI = AppConstant.chatApi + AppConstant.antiFraudFlagsAPI;
  private fraudFlagsAPI = AppConstant.chatApi + AppConstant.fraudFlagsAPI;
  private deleteRatingReview = AppConstant.apiUrl + AppConstant.deleteRatings;
  private npsScore = AppConstant.apiUrl + AppConstant.getNpsScores;
  private npsScoreAddUpdate = AppConstant.apiUrl + AppConstant.addUpdateNpsScores;
  private consultantPrice = AppConstant.apiUrl + AppConstant.consultantPrice;
  private checkStatusApi = AppConstant.apiUrl + AppConstant.checkStatus;
  private continueChatCallApi = AppConstant.apiUrl + AppConstant.continueChatCallApi;

  private getKundli = AppConstant.apiUrl + AppConstant.getKundli;
  private saveHoroscopeGetID = AppConstant.apiUrl + AppConstant.saveHoroscope;

  constructor(private http: HttpWrapperService) {
  }

  getConsultantPrice = (params) => {
    return this.http.get(this.consultantPrice, params, true);
  }

  checkChatCallStatus = (params) => {
    return this.http.postParams(this.checkStatusApi, params, true);
  }

  continueChatting = (params) => {
    return this.http.postParams(this.continueChatCallApi, params, true);
  }

  getAntiFraudFlags = (params) => {
    return this.http.get(this.antiFraudFlagsAPI, params, true);
  }

  getFraudFlags = (params) => {
    return this.http.get(this.fraudFlagsAPI, params, true);
  }

  getAstrologers = (params, isLogin = false) => {
    return this.http.get(this.astrologerListForCallApi, params, isLogin);
  }

  checkChatStatus = (params) => {
    return this.http.postParams(this.checkChatStatusApi, params, true);
  }

  checkNextOnlineTime = (params) => {
    return this.http.get(this.checkNextOnlineTimeApi, params, true);
  }

  getBalence = (params) => {
    return this.http.get(this.walletBalenceApi, params, true);
  }

  notify = (body) => {
    return this.http.postParams(this.notifyConsultantApi, body, true);
  }

  getIntakeData = (params) => {
    return this.http.get(this.intakeDataApi, params, true);
  }

  submitIntakeData = (body) => {
    return this.http.postHeaderParams(this.postIntakeDataApi, body, true);
  }

  submitInitiateChat = (body) => {
    return this.http.post(this.initiateChatApi, body, true);
  }

  orderInitiateChat = (body) => {
    return this.http.post(this.createChatOrderApi, body, true);
  }

  getWaitList = (params) => {
    return this.http.get(this.waitListApi, params, true);
  }

  getChatStatus = (params) => {
    return this.http.get(this.chatOrderApi, params, true);
  }

  getChatList = (params) => {
    return this.http.get(this.chatListApi, params, true);
  }

  getLastMessage = (params) => {
    return this.http.get(this.lastMessageApi, params, true);
  }

  sendChatMessage = (body) => {
    return this.http.postHeaderParams(this.sendMessageApi, body, true);
  }

  chatEnd = (params) => {
    return this.http.postHeaderParams(this.chatEndedApi, params, true);
  }

  ratting = (params) => {
    return this.http.postParams(this.rattingApi, params, true);
  }

  deleteMessage = (params) => {
    return this.http.deleteParams(this.deleteMessageApi, params, true);
  }

  getSharedChat = (params) => {
    return this.http.get(this.sharedChatApi, params);
  }

  public getCitySuggestion = (params) => {
    return this.http.get(this.getSuggestion, params, false);
  }

  public getSecondPlaces = (params) => {
    return this.http.get(this.getSecondPlace, params, false);
  }

  public addAddress = (params) => {
    return this.http.postHeaderParams(this.addNewCity, params, false);
  }

  deleteRating = (params) => {
    return this.http.postParams(this.deleteRatingReview, params, true);
  }

  getNpsScore = (params) => {
    return this.http.get(this.npsScore, params, true);
  }

  addUpdateNpsScore = (params) => {
    return this.http.postParams(this.npsScoreAddUpdate, params, true);
  }

  public getKundliList = (params) => {
    return this.http.get(this.getKundli, params, true);
  }

  public saveHoroscopeAndGetID = (params, body) => {
    return this.http.postHeaderBodyAndParams(this.saveHoroscopeGetID, params, body, true);
  }
}
